<template>
  <div>
    <v-dialog v-model="dialogVisible" fullscreen>
      <v-card class="product-detail-media-gallery-modal-container">
        <button class="btn-close" @click="closeModal">
          <v-icon class="icon-close">
            mdi-close
          </v-icon>
        </button>

        <div class="product-detail-media-gallery-modal">
          <div
            v-for="(item, index) in originalMedias"
            :key="index"
            ref="productDetailMediaGalleryItem"
            class="product-detail-media-gallery-item"
          >
            <div v-if="item.imageUrl" class="image-container">
              <img
                class="image"
                :src="item.imageUrl"
                :alt="item.alt"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    media: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    originalMedias() {
      return this.media.map(item => {
        return {
          ...item,
          imageUrl: item.imageUrl.slice(
            item.imageUrl.indexOf('https://', 1),
          ),
        }
      })
    },
  },
  data() {
    return {
      dialogVisible: false,
    }
  },
  methods: {
    openModal(event) {
      this.dialogVisible = true

      setTimeout(() => {
        const mediaGalleryElementActive =
          this.$refs.productDetailMediaGalleryItem[event] ?? null

        if (mediaGalleryElementActive) {
          this.$nextTick(() => {
            mediaGalleryElementActive.scrollIntoView({
              block: 'start',
            })
          })
        }
      }, 0)
    },
    closeModal() {
      this.dialogVisible = false
    },
  },
}
</script>

<style lang="stylus" scoped>
.product-detail-media-gallery-modal-container {
    padding: 20px 110px !important
}
.product-detail-media-gallery-modal {
	width: 100%;

	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 15px;

	.image-container {
		min-height: 200px;

		.image {
			width: 100%;
			height: 100%;
			cursor: default;
		}
	}
}

.btn-close {
    position fixed;
    top: 20px;
    right: 50px;

    width: 40px;
    height: 40px;

    background: transparent;
    border: 1px solid rgba(#121212, 0.1);
    border-radius: 50% !important;

    &:hover {
        .icon-close {
            color: rgba(0,0,0,0.72)
        }
    }

    &:focus {
        outline: .125rem solid #0071e3;
        outline-offset: .125rem;
        outline-offset: .125rem;
    }

    .icon-close {
        color: rgba(18,18,18,0.25)
    }


}
</style>
